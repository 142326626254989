import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {NavLink} from "react-router-dom";
import ScrollTo from "../../common/ScrollTo";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {classList} from "../../../utils";

const TopBar = () => {
    function helpPage() {
        window.location.href = '/help';
    }

    function helpTerms() {
        window.location.href = '/terms';
    }

    function helpPolicy() {
        window.location.href = '/privacy-policy';
    }

    return (
        <section
            className={classList({
                header: true,
            })}
            style={{height: "80px"}}
        >
            <div className="container header-container">
                <div className="brand">
                    <a href={"/"}> <img src="/assets/images/ifapt/ifapt_logo.png" alt=""/></a>
                </div>
                <div className="float-right">
                    <ul className="navigation">
                        <li>
                            <a onClick={helpTerms} style={{cursor: "pointer"}}>Terms of Use</a>
                        </li>
                        <li>
                            <a onClick={helpPolicy} style={{cursor: "pointer"}}>Privacy Policy</a>
                        </li>
                    </ul>
                </div>

                <div className="m-auto"/>
            </div>
        </section>
    );
};
export default TopBar;