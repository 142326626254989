import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Icon,
    Grid,
    Button,
    TextField,
    InputAdornment,
} from "@material-ui/core";
import {darken} from "@material-ui/core/styles";
import FacebookIcon from "../../common/icons/FacebookIcon";
import TwitterIcon from "../../common/icons/TwitterIcon";
import InstagramIcon from "../../common/icons/InstagramIcon";
import LinkedinIcon from "../../common/icons/LinkedinIcon";
import clsx from "clsx";

const useStyles = makeStyles(({palette, ...theme}) => ({
    section: {
        background: "#006400",
        color: palette.primary.contrastText,
    },
    termsTop: {
        marginTop: "18px"
    },
    iconWrapper: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        padding: 4,
        borderRadius: 4,
        marginRight: 12,
        border: `1px solid ${palette.primary.contrastText}`,
        cursor: "pointer",
    },
    link: {
        borderRadius: 4,
        "&:hover": {
            background: darken("#006400", 0.2),
        },
    },

    links: {
        color: "#f2dc0a"
    }
}));

const companyOption = ["About Us", "Services", "Team", "Pricing", "Project"];

const userfulLink = [
    "Terms of Services",
    "Privacy Policy",
    "Documentation",
    "Changelog",
    "Components",
];

const Footer2 = () => {
    const classes = useStyles();

    return (
        <section className={`section ${classes.section}`} id="contact4">
            <div className="container">
                <Grid container spacing={3}>
                    <Grid item lg={9} md={6} sm={6} xs={12}>
                        <div className="footer1__about">
                            <h4 className="text-24 font-normal m-0">About IFAPT</h4>
                            <p className="my-6 max-w-500 text-inherit">
                            IFAPT provides Elite football-training program combined with Academics (optional) to give the athletes a great opportunity to train with professional Portuguese football coaches and experience elite Portuguese league competition while continuing education and click here for more details <a className={classes.links} href="https://ifapt.com" target="_blank" >www.ifapt.com</a> .
                            </p>
                            <div className={classes.termsTop}>
                                <div>
                                    For support, please go through our <a style={{color: "#f2dc0a"}}
                                                                          href="https://ifapt.com/faqs/">FAQ</a> section or write
                                    to <a style={{color: "#f2dc0a"}}
                                          href="mailto:info@ifapt.com"> info@ifapt.com</a>
                                </div>
                            </div>
                            <div className={classes.termsTop}>
                                <div>Copyright &#169;  2023 IFAPT. All Rights Reserved</div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <div className="flex flex-wrap">
                            <div className={classes.iconWrapper}>
                                <FacebookIcon className="text-13" fontSize="small"/>
                            </div>
                            <div className={classes.iconWrapper}>
                                <InstagramIcon className="text-13" fontSize="small"/>
                            </div>
                        </div>
                        <div className={classes.termsTop}>
                            <div><a href={"/terms"}>Terms of Use</a></div>
                            <div><a href={"/privacy-policy"}>Privacy Policy</a></div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
};

export default Footer2;
