import React, {useEffect} from "react";
import {scrollTo} from "../utils";
import TopBar4 from "./sections/ifapt/TopBar4";
import Intro4 from "./sections/ifapt/Intro4";
import Features4 from "./sections/ifapt/Features4";
import Features2 from "./sections/ifapt/Features2";
import Footer2 from "./sections/ifapt/Footer2";
import AboutMain from "./sections/ifapt/AboutMain";

const Pc = () => {
    useEffect(() => {
        scrollTo("root");
    });

    return (
        <div className="landing">
            <TopBar4/>
            <Intro4/>
            <Features2/>
            <Features4/>
            <AboutMain/>    
            <Footer2/>
        </div>
    );
};

export default Pc;
