import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Icon } from "@material-ui/core";
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  para_style: {
    textAlign: "justify",
  },
  fontHeading: {
    fontSize: "32px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      fontSize: "28px",
    },
  },
  musicImage: {
    width: "362px",
    borderRadius: "10px",
    marginTop: "-50px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0px",
      marginLeft: "0px",
    },
  },
  orangeHeading: {
    color: "#006400",
    fontSize: "24px",
    marginLeft: "10px",
    marginTop: "10px"
 // Adjust the size as needed
  },

  paraContainer: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    marginTop: "50px"
  },

  paraLevel: {
    padding: "60px",
    paddingTop: "10px",
    paddingBottom: "20px"
  },

  footBall: {
    animation: '$rotation 15s infinite linear',
  },
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
}));

const AboutMain = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <section className=" mt-15" id="intro4">
      <div className="container">
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div>
              <p className="text-primary m-0 mb-4 text-20">
                <h1 className={classes.fontHeading} >
                  IFAPT HUB Coaching Modules
                </h1>
              </p>
              <p className={`max-w-500 mb-3 ${classes.para_style} `}>
              IFAPT HUB's Coaching Modules offer a progressive pathway for football coaches. Begin with Coaching Level 1, aimed at U6 to U11 players, to nurture their love for the game and develop core technical skills. Advance to Level 2 for transitioning to 11v11 on a full field and mastering the four moments of the game.
              </p>
              <p className={`max-w-500 mb-3 ${classes.para_style} `}>
              Coaching Level 3 focuses on team play and specific systems of play, diving into offensive and defensive principles. Level 4 caters to senior players, emphasizing tactical and physical development and offering insights into various game-related topics.
              </p>

              <p className={`max-w-500 mb-3 ${classes.para_style} `}>
              Coming soon, our Goalkeepers program, Fitness curricula, Club & Risk Management, Footballers Nutrition, and Football Psychology will further enhance your coaching skills. Join IFAPT HUB and become the coach every player dreams of!
              </p>
            </div>

            
            {/* Merge the event details here */}
            <div>

              {/* level 1 */}
              <div className={classes.paraContainer} >
                <SportsSoccerIcon color="primary" className={`text-54 ${classes.footBall}`}/><h2 className={classes.orangeHeading}>LEVEL 1</h2>
              </div>
              
              <p className={classes.paraLevel} >The coaching level 1 module is a complete technical tactical progressive sessions collection along with age-appropriate recommendations in the four pillars of youth development process for a footballer. IFAPT HUB Level 1 Coaching Module is designed for coaches who are coaching youth players between the ages of U6 to U11. The module will cover the core objectives along with recommendations to build the first love for the game of football as well as the relationship with the ball. Many simple fun ideas and training sessions are selectively chosen to bring about those core technical competencies such as dribbling, shooting, finishing and running with the ball to score a goal in a fun, creative and exciting environment. </p>

              {/* level 2 */}
              <div className={classes.paraContainer} >
                <SportsSoccerIcon color="primary" className={`text-54 ${classes.footBall}`}/><h2 className={classes.orangeHeading}>LEVEL 2</h2>
              </div>
              
              <p className={classes.paraLevel} >The coaching level 2 module is a complete technical tactical progressive sessions library with specific recommendations for coaches on how to transit from small-sided games of 7v7 to 11v11 on a full pitch. It’s referred, as a critical stage of youth development as coaches has to systematically choose the right parameters to make this transition for the youth players from football 7 to football 11 as its referred to in IFAPT Academy Program.
              The Level 2 Coaching module will continue to cover all the key components of the technical and individual skills development and start introducing the four moments of the game as its taught at IFAPT Football Academy:
              </p>
                <ul className="ml-14" >
                  <li>The offensive moment </li>
                  <li>The defensive moment </li>
                  <li>The offensive transition </li>
                  <li>The defensive transition </li>
                </ul>
                <p className={classes.paraLevel} >At IFAPT HUB  we have chosen these training sessions along with the recommendations as required to make this an enjoyable journey for the youth and teach the game of football in a fun and progressive manner.</p>

              {/* level 3 */}
              <div className={classes.paraContainer} >
                <SportsSoccerIcon color="primary" className={`text-54 ${classes.footBall}`}/><h2 className={classes.orangeHeading}>LEVEL 3</h2>
              </div>
              
              <p className={classes.paraLevel} >The Coaching Level 3 Module is a comprehensive library of training sessions and recommendations based on a progressive development of players as a team to play a specific system of play. This module covers the key technical tactical progressive components of the game more specifically in each sector and corridor of the field.  <br /> <br /> This stage of the player’s development is focused on very specific principles of the game. Such as offensive principles of the game from constructing the attack, creating chances to finishing situations. These actions are thought through a collective process with such topics as; progression, offensive coverage, mobility, and use of space.  Similarly the defensive principles are enforced at an in-depth level of understanding in preventing the buildup of the attack or how to disrupt the attack, and preventing of situations such as finishing at the goal. <br /> <br />  At IFAPT HUB we have carefully selected the specific training sessions along with the progressive team development recommendations to make it easier for coaches to follow and add their own flare and style of coaching to bring about the best outcome in a team setting. </p>

              {/* level 4 */}
              <div className={classes.paraContainer} >
                <SportsSoccerIcon color="primary" className={`text-54 ${classes.footBall}`}/><h2 className={classes.orangeHeading}>LEVEL 4</h2>
              </div>
              
              <p className={classes.paraLevel} >This course is designed for all coaches working with senior players between the ages 19 & above. Fundamentally this course material will continue to cover the principles of Football in the technical, tactical, physical and mental components of the game. However, more and more emphasis will be based on player’s tactical and physical skills development. In addition, basic tactical game related topics would be introduced such as:  </p>
              <ul className="ml-14" >
              <li>The offensive moment </li>
                  <li>The defensive moment </li>
                  <li>The offensive transition </li>
                  <li>The defensive transition </li>
                </ul>
                <p className={classes.paraLevel} >Key focus will be applied to the physical development of the player. Long-term habits are built as he or she progresses down the path of football as a career or seeking college scholarship or position. The training sessions and recommendations are designed to give the coaches how to plan their weekly training sessions based on which part of the season they are in, for example pre-season, in-season and when there are two games per week or one game per week. These are just some standard ideas for coaches to have as they begin their journey in this profession. As for those who have been doing this for a long time there might be some ideas they can take from this module to enhance or confirm their current training and planning schedule. </p>
                
              {/* level 5 */}
              <div className={classes.paraContainer} >
                <SportsSoccerIcon color="primary" className={`text-54 ${classes.footBall}`}/><h2 className={classes.orangeHeading}>LEVEL 5</h2>
              </div>
              
              <p className={classes.paraLevel} >This Module is designed to provide some key features of each System of Play. This Module will cover key explanations organized as per the moments of the game. </p>
              <ul className="ml-14" >
                  <li>Attacking principles  </li>
                  <li>Defensive principles  </li>
                  <li>Switching point of attack   </li>
                  <li>Counter attack  </li>
                  <li>Playing from of the back 1/3   </li>
                  <li>Playing from the final 1/3   </li>
                  <li>Pressing, zonal and marking defensive principles   </li>
                  <li>•	Systems of play (4-4-2 & 4-3-3) and team shape  </li>
                </ul>
                <p className={classes.paraLevel} >The systems that we will be discussing is 4-3-3 our goal is to provide the coaches and players with all the details on how to develop a game model or for players to understand the game model and how to apply specific team formations as per the personnel and opponent or other conditions the coach faces on that particular match day. The Module will also cover the strengths and weakness of the system, with key focus on the defensive formation, the midfield formation and the attackers. Also, some of the key attributes defined as per the physical, technical, and tactical components of players in each position including the goalkeepers.
                  This information though comprehensive yet it is not exclusive and will only give the coaches some key foundation points of each system of play and their strengths and possible formations within the systems. Our IFAPT Curricula” further explores the details of developing a comprehensive 4-3-3 system of play with specific number system as in most of Iberian region and other parts of Europe.
                  The Set-Plays are guidelines & feedback for defensive play and some attacking plays such as defensive formation for corner kicks, indirect free kicks, direct free kicks, goal kicks, throw-ins and penalties. This information should be used as a stepping-stone for formulating your own strategies and systems to counter the opponents, based on your personnel in the team.
                  </p>

                <p className={classes.paraLevel} >About the 4-4-2 System of Play <br />About the 4-3-3 System of Play <br /> About the 3-5-2 System of Play </p>
            </div>

              {/* Content */}
              <div className={classes.paraContainer} >
                <SportsSoccerIcon color="primary" className={`text-54 ${classes.footBall}`}/><h2 className={classes.orangeHeading}>Goalkeepers program</h2>
              </div>
              
              <p className={classes.paraLevel} >The goalkeepers training curriculum focused on all the moments of the game along with all the different attributes of Goalkeepers. All content organized in different moments of the game such as shot stopping, Depth management, offensive organization and defensive organization all drills with detailed drills for each moment. 
              This curriculum will be divided into 3 levels such as basic level, intermediate, and advance level.  Each level will define all the four moments of the game.
              </p>

                {/* Content */}
                <div className={classes.paraContainer} >
                <SportsSoccerIcon color="primary" className={`text-54 ${classes.footBall}`}/><h2 className={classes.orangeHeading}>Football Fitness curricula</h2>
              </div>
              
              <p className={classes.paraLevel} >Fitness training from childhood to adulthood with all components stages of development , speed & acceleration, strength, explosive power, agility and coordination, Endurance and flexibility of fitness defined and organized with sample training plans and supporting audi- video aids in the upgrades of the App. The Fitness Curricula will be available in two separate modules:
<br /> <br />
Fitness module 1: Childhood & Pre-Puberty Fitness preparation 
<br />
Fitness module 2:  Circum Puberty and Adult fitness preparation

              </p>

            {/* Content */}
            <div className={classes.paraContainer} >
                <SportsSoccerIcon color="primary" className={`text-54 ${classes.footBall}`}/><h2 className={classes.orangeHeading}>Club & Risk Management Module</h2>
              </div>
              
              <p className={classes.paraLevel} >Risk management, League organization, club bylaws, philosophy and vision ideas combined. Every club must have this information and guidelines to develop a solid fundamental in your organization. </p>

              {/* Content */}
            <div className={classes.paraContainer} >
                <SportsSoccerIcon color="primary" className={`text-54 ${classes.footBall}`}/><h2 className={classes.orangeHeading}>Footballers Nutrition Guidelines</h2>
              </div>
              
              <p className={classes.paraLevel} >Nutrition content with word doc and audio videos content. From Nutrition history to post game meals recommendations delivered in an easy way to understand and follow. All these modules shall be available in IFAPT HUB App.</p>

              {/* Content */}
            <div className={classes.paraContainer} >
                <SportsSoccerIcon color="primary" className={`text-54 ${classes.footBall}`}/><h2 className={classes.orangeHeading}>Football Psychology guidelines for Coaches</h2>
              </div>
              
              <p className={classes.paraLevel} >This section will have 4 separate age categories and recommendations for coaches on how to deal with youth and provide time tested and proven guidelines when instructing or mentoring the youth. 
Includes audio video lectures by Dr. Hancock for easy understanding and implementing of youth developmental strategies as a youth coach all in App upgrades.
</p>

              {/* Content */}
            <div className={classes.paraContainer} >
                <SportsSoccerIcon color="primary" className={`text-54 ${classes.footBall}`}/><h2 className={classes.orangeHeading}>Personality Assessment Tests and Evaluation</h2>
              </div>
              
              <p className={classes.paraLevel} >Leadership and Communication skills section powered by FourLenses of Shipley Communications. Used by USA government, major corporations and educational institutions. A must for everyone especially the coaches, players and parents to identify theirs strengths, weaknesses and tools for improving these attributes.</p>
            
            {/* final content */}
              <div className={classes.paraContainer} >
                <SportsSoccerIcon color="primary" className={`text-54 ${classes.footBall}`}/><h2 className={classes.orangeHeading}>IFAPT HUB Overall benefits to Coaches</h2>
              </div>
              
              <p className={classes.paraLevel} >The App Is built to help coaches answer all the questions listed below:
              </p>

              <ol class="list-decimal ml-6">
                <li>
                    <p>Where do I begin: How to build a team: How to get started? What age-appropriate training sessions could I conduct? And more?</p>
                    <ul class="list-inside list-disc">
                        <li>a) the inheritance of a team or the building of a team, the city, the expectations and the time of the season</li>
                        <li>b) the scouting process as well as the connectivity to the hiring process based on your needs and voids on the team</li>
                        <li>c) the final roster and the fine tuning. The game model or style and your philosophy.</li>
                    </ul>
                </li>
                <li>
                    <p>The beginning or the First steps of preparation of a team:</p>
                    <ul class="list-inside list-disc">
                        <li>a) The testing and Evaluation stages of athletes and batteries of tests and procedures for athletic and physical evaluation includes medical examination.</li>
                        <li>b) The pre-season Training plans and ideas including the fitness development under the tactical periodization</li>
                        <li>c) develop a specific strategy and style of play as a team or club, focusing on all four moments of the game and test them in training sessions.</li>
                    </ul>
                </li>
                <li>
                    <p>The Rational to choose the system of play:</p>
                    <ul class="list-inside list-disc">
                        <li>a) the descriptions and details of your system of play</li>
                        <li>b) How to develop and teach that particular system of play</li>
                        <li>c) the positions, the profiles and the personal characteristics or attributes of the individuals (players) to play this style and system of play</li>
                    </ul>
                </li>
                <li>
                    <p>The Game preparation or in-season training plans and drills</p>
                    <ul class="list-inside list-disc">
                        <li>a) The style, the specification of a philosophy of each coach</li>
                        <li>b) Breaking down the system from back to front and front to back</li>
                        <li>c) Game by Game preparation training plans</li>
                        <li>d) the fixing and changing recommendations</li>
                        <li>e) the adaptation of its personal and management</li>
                    </ul>
                </li>
                <li>
                    <p>The Partnership between the coach, the players and the owners or management</p>
                    <ul class="list-inside list-disc">
                        <li>a) the dos and don’t of avoiding pitfalls and failures</li>
                        <li>b) how to keep a working relationship with Mgt and play a leadership role in the organization and with your team.</li>
                    </ul>
                </li>
            </ol>

            <div className="mb-25" ></div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default AboutMain;
