import React from "react";
import {SvgIcon} from "@material-ui/core";

const InstagramIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 21.422 21.422">
            <g
                id="Group_170"
                data-name="Group 170"
                transform="matrix(1, -0.017, 0.017, 1, 0, 0.368)"
            >
                <path
                    id="Path_139"
                    data-name="Path 139"
                    d="M15.246,0H5.811A5.818,5.818,0,0,0,0,5.811v9.435a5.818,5.818,0,0,0,5.811,5.811h9.435a5.818,5.818,0,0,0,5.811-5.811V5.811A5.818,5.818,0,0,0,15.246,0Zm3.943,15.246a3.947,3.947,0,0,1-3.943,3.943H5.811a3.947,3.947,0,0,1-3.943-3.943V5.811A3.947,3.947,0,0,1,5.811,1.868h9.435a3.947,3.947,0,0,1,3.943,3.943v9.435Z"
                />
                <path
                    id="Path_140"
                    data-name="Path 140"
                    d="M5.426,0a5.426,5.426,0,1,0,5.426,5.426A5.432,5.432,0,0,0,5.426,0Zm0,8.983A3.558,3.558,0,1,1,8.984,5.426,3.562,3.562,0,0,1,5.426,8.983Z"
                    transform="translate(5.103 5.103)"
                />
                <path
                    id="Path_141"
                    data-name="Path 141"
                    d="M1.37,0A1.37,1.37,0,0,0,.4,2.339a1.37,1.37,0,0,0,1.937,0A1.37,1.37,0,0,0,1.37,0Z"
                    transform="translate(14.812 3.519)"
                />
            </g>
        </SvgIcon>
    );
};

export default InstagramIcon;
