import React from "react";
import {makeStyles, } from "@material-ui/core/styles";
import {Grid, useMediaQuery } from "@material-ui/core";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';


const IMG = [
    {id: 1, img: "/assets/images/screenshot/1.png", description: "For Registration enter your email"},
    {id: 2, img: "/assets/images/screenshot/2.png", description: "Enter the OTP for complete the registration"},
    {id: 3, img: "/assets/images/screenshot/4.png", description: "Now you need setup the password"},
    {id: 4, img: "/assets/images/screenshot/3.png", description: "Enter the your name after the registration is completed"},

    
]

const IMG1 = [
    {id: 1, img: "/assets/images/screenshot/1a.png", description: "Enter your email to check if this account exists and then proceed to log in using your email and password"},
    {id: 2, img: "/assets/images/screenshot/5.png", description: "The current screen displays a list of modules. Please select the module you'd like to access"},
    {id: 3, img: "/assets/images/screenshot/2a.png", description: "After selecting the module, you will be able to view its content. To access the content"},
    {id: 4, img: "/assets/images/screenshot/3a.png", description: "Click 'Enroll' and follow the on-screen payment instructions, making sure international transactions are enabled."},
]

const useStyles = makeStyles(({palette, ...theme}) => ({
    img: {
        width: "250px",
    },

    container_img: {
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center"
        },
    },

    container_of_img: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },

    step: {
        fontWeight: "bold",
        color: "#006400 !important",
        margin: "0px",
        marginBottom: "-10px",
        marginTop: "50px"
    },
    description: {
        width: "200px",
        textAlign: "center"
    },

    reg: {
        fontSize: "25px",
        color: "#006400 !important",
        fontWeight: "bold",
        marginBottom: "-15px",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        marginTop: "50px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "20px",
            textAlign: "center",
            marginLeft: "35px",
        },
    },
    arrow: {
        color: "#006400 !important",
        fontWeight: "bold",
        marginTop: "70px",
        [theme.breakpoints.down("xs")]: {
            transform: "rotate(90deg)"
        },
    },
    howitswork: {
        fontSize: "32px",
        textAlign: "center",
        color: "#006400 !important",
        fontWeight: "bold",
        marginBottom: "-15px",
        marginTop: "50px",
        
    },
    footBall: {
        animation: '$rotation 15s infinite linear',
        fontSize: "30px",
      },
      '@keyframes rotation': {
        from: {
          transform: 'rotate(0deg)',
        },
        to: {
          transform: 'rotate(359deg)',
        },
      },

      paraReg: {},

      container_for_image: {
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
            display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center"
        },
       
      },

      paraP: {
        textAlign: "center"
      }
}));

const Features2 = () => {
    const classes = useStyles();

    const isMobile = useMediaQuery("(max-width: 768px");

    return (
        <section className="section" id="Features2">
            <div className="container" >
            <p className={classes.howitswork} >How it works</p>

            <p className={classes.paraP} >To register, sign in, and complete a purchase, follow the step-by-step instructions presented in the screenshots below.</p>

            <p className={classes.reg} ><SportsSoccerIcon color="primary" className={`text-54 ${classes.footBall}`}/>Registration Proccess</p>
            
            <div className={`container ${classes.container_img} `} >
                
               {
                IMG.map((img, index) => (
                    <div className={classes.container_for_image}>
                    <div className={classes.container_of_img} >  
                        <p className={classes.step} >Step : {img.id}</p>
                        <p className={classes.description} >{img.description}</p>
                        <img src={img.img} className={classes.img} alt="img" />
                    </div>
                    {index !== (IMG.length - 1) &&
                        <ArrowRightAltIcon className={classes.arrow} />
                    }
                    
                    </div>
                ))
               }
               </div>

                <p className={classes.reg} ><SportsSoccerIcon color="primary" className={`text-54 ${classes.footBall}`}/>Purchasing Proccess</p>

                <div className={`container ${classes.container_img} `} >
                {
                IMG1.map((img, index) => (
                    <div className={classes.container_for_image} >
                    <div className={classes.container_of_img} >  
                        <p className={classes.step} >Step : {img.id}</p>
                        <p className={classes.description} >{img.description}</p>
                        <img src={img.img} className={classes.img} alt="img" />
                    </div>
                    {index !== (IMG1.length - 1) &&
                        <ArrowRightAltIcon className={classes.arrow} />
                    }
                    
                    </div>
                ))
               }
            </div>
            </div>
        </section>
    );
};

export default Features2;
