import React, { useEffect } from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, useMediaQuery} from "@material-ui/core";
import Feature4IconCreator from "../../common/icons/Feature4IconCreator";
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import Bounce from 'react-reveal/Jump';
import Spin from 'react-reveal/HeadShake';

const useStyles = makeStyles((theme) => ({
    feature: {
        [theme.breakpoints.down("xs")]: {
            textAlign: "center !important",
        },
    },

    musicImage: {
        width: "362px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },

    musicImageWrapper: {
        position: "relative",
        textAlign: "right",

        // marginLeft: "100px",
        "&::before": {
            content: '" "',
            position: "absolute",
            display: "block",
            height: "81%",
            top: "11%",
            left: 0,
            right: 0,
            margin: "0 -2000px 0 -80px",
            borderRadius: "300px",
            background: theme.palette.primary.main,
            zIndex: -1,
            opacity: 0.1,

            [theme.breakpoints.down("xs")]: {
                display: "none",
            },
        },

        "&::after": {
            content: '" "',
            position: "absolute",
            display: "block",
            height: 36,
            width: "80%",
            marginTop: 32,
            left: "calc(10% + 20px)",
            opacity: 0.75,
            background:
                "radial-gradient(ellipse at center,  rgba(0,0,0,0.65) -25%,rgba(0,0,0,0) 55%);",
        },

        [theme.breakpoints.down("sm")]: {
            display: "block",
            "&::after": {
                display: "none",
            },
            "&::before": {
                display: "none",
            },
        },
    },

    para: {
        fontSize: "10px",
        fontStyle: "italic"
    },

    football: {
        width: "70px",
        animation: '$rotation 15s infinite linear',
    },
    '@keyframes rotation': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(359deg)',
      },
    }

   
}));

const Feature4 = () => {
    const classes = useStyles();

  
    const [isHover, setIsHover] = React.useState(false);

    

        const handleMouseEnter = () => {
            setIsHover(true);
            console.log("hello")
        };

        const handleMouseLeave = () => {
            setIsHover(false);
        };

    const isMobile = useMediaQuery("(max-width: 768px");

    return (
        <section className="section" id="feature4">
            <div className="container">
                <Grid container spacing={isMobile ? 3 : 10} alignItems="center">
                    <Grid item lg={8} md={6} sm={6} xs={12}>
                        <div className="max-w-450 mb-16">
                            <h1 className="mt-0 font-normal text-40">App Key Features</h1>
                            <p>
                                The app is offers seasonal plans to help coaches structure their training and prepare their teams effectively throughout the year and it's includes several level of football tutorial(level 1 to level 6).
                            </p>
                        </div>


                        <Grid container spacing={isMobile ? 5 : 8}>
                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>

                                            <Bounce delay={5} >
                                                    <img className={classes.football} onMouseEnter={handleMouseEnter}
                                                    onMouseLeave={handleMouseLeave} src="./assets/images/ifapt/football.png" alt="img" />
                                            </Bounce>
                                          
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">Level 1</h4>
                                    <p className={classes.para} >IFAPT HUB Level 1 Coaching Module equips coaches working with youth players aged U6 to U11 with age-appropriate training sessions to develop core technical skills and a love for the game.</p>
                                </div>
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <Bounce delay={1000}>
                                            <img className={classes.football} onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave} src="./assets/images/ifapt/football.png" alt="img" />
                                        </Bounce>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">
                                        Level 2
                                    </h4>
                                    <p className={classes.para} >IFAPT Level 2 Coaching: A seamless transition from 7v7 to 11v11 with a focus on technical, tactical, and game moments for youth development.</p>
                                </div>
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <Bounce delay={2000}>
                                            <img className={classes.football} onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave} src="./assets/images/ifapt/football.png" alt="img" />
                                        </Bounce>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">Level 3
                                    </h4>
                                    <p className={classes.para} >IFAPT HUB's Coaching Level 3 Module provides coaches with a detailed framework for advancing player development and team play, emphasizing both offensive and defensive principles.</p>
                                </div>
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <Bounce delay={3000}>
                                            <img className={classes.football} onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave} src="./assets/images/ifapt/football.png" alt="img" />
                                        </Bounce>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">Level 4
                                    </h4>
                                    <p className={classes.para} >Advanced coaching course emphasizing tactical and physical development for senior players, including topics like attacking, defense, and game systems.</p>
                                </div>
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <Bounce delay={4000}>
                                            <img className={classes.football} onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave} src="./assets/images/ifapt/football.png" alt="img" />
                                        </Bounce>
                                        
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">Level 5
                                    </h4>
                                    <p className={classes.para} >This module provides key insights into the 4-3-3 System of Play, offering coaches and players a comprehensive understanding of its strengths, formations, and attributes, serving as a foundational guide for developing their game model.</p>
                                </div>
                            </Grid>

                    
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12} className={classes.feature}>
                        <div className={classes.musicImageWrapper}>
                            <img
                                className={classes.musicImage}
                                src="/assets/images/ifapt/dashboard-1.png"
                                alt="mobile-1"
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
};

export default Feature4;
