import React from "react";

const TopBarMobile = () => {
    return (
        <div style={{textAlign: "center"}}>
            <a href={"/"}>
                <img width="100px" style={{marginBottom: "-70px", marginTop: "30px"}} src="/assets/images/ifapt/ifapt_logo.png" alt=""/>
            </a>
        </div>
    );
};

export default TopBarMobile;